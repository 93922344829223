@import url('https://fonts.googleapis.com/css?family=Play:700');

/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure, 
footer, header, hgroup, menu, nav, section {
  display: block;
}
body {
  line-height: 1;
}
ol, ul {
  list-style: none;
}
blockquote, q {
  quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
  content: '';
  content: none;
}
table {
  border-collapse: collapse;
  border-spacing: 0;
} 

*, body, html {
  box-sizing:border-box;
}

body {
  font-family: 'Play', sans-serif;
  background: #fff;
  color:#000
}


header {
  padding:17px 0;
  overflow:hidden;
}

header .logo {
  float:left;
  color:#000;
  width: 3.13rem;
  height: 3.13rem;
  cursor:pointer;
  margin-left: 1.88rem;
  margin-right: 0.31rem;
  margin-top: 0.31rem;
}

#logo-title {
  color: #626890;
  float:left;
  margin-top:15px;
  cursor:pointer;
  font-size: 1.5rem;
}

.menu {
  float:right;
}

.menu a {
  color: #000;
  text-decoration:none;
  display:inline-block;
  padding:10px 20px;
  border-radius:3px;
  transition: all 0.3s ease-in-out;
  cursor:pointer;
}

.menu label {
  display:none;
}

.menu #toggle {
  display:none;
}

.menu a:hover {
  background:#0f0449;
  color:#fff;
  cursor:pointer;
}

#home .bitcoin-logo {
  width:100%;
  margin-top:90%;

}

.title {
  color:#626890;
  height: 10vh;
 
}


.e-container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 25vh;
    text-align: center;
}

h2 {
  font-size:1.5rem;
}

h3 {
  font-size: 1.2rem;
  margin-top: 3rem;
  margin-bottom: 2rem;
  color:#0f0449;
}

.input-container {
  width: 48rem;
  display: flex;
  flex-direction: row;
}

label {
  display:block;
  text-transform:uppercase;
  font-weight:800;
  color: #626890;
  margin-bottom:20px;
}

input[type="text"] {
  background:transparent;
  color:#000;
  display:block;
  width:48rem;
  padding:10px 15px;
  font-size:2rem;
  margin-bottom:20px;
  font-weight:100;
  border: 2px solid #000;
}

input:focus {
  outline: 2px solid #626890;
}


button {
  background:#626890;
  padding: 10px 15px;
  font-size: 1.5rem;
  border:none;
  float:right;
  color:white;
  font-weight:300;
  border-radius:5px;
  transition: all 0.3s ease-in-out;
}

button:hover {
  background:green;
}

/* Results */

#results {
  text-align:center;
}

#results1 {
  margin-top: 4rem;
}

#results h1 {
  font-size:3rem;
  font-weight:100;
  text-align:center;
  color:green;
  margin: 30px 0;
}

#results1 h1 {
  font-size:3rem;
  font-weight:100;
  text-align:center;
  color:green;
  margin: 30px 0;
}

#results h3 {
  text-align:center;
  font-size:1.8rem;
  color:#fff;
  margin-top:50px;
}
#results1 h3 {
  text-align:center;
  font-size:1.8rem;
  color: #000;;
  margin-top:50px;
}

#results h4 {
  text-align:center;
  font-size:1.3rem;
  font-weight:300;
  color:white;
}

#results1 h4 {
  text-align:center;
  font-size:1.3rem;
  font-weight:300;
  color:white;
}

#buy-bitcoin {
  font-size: 1.75rem;
}

#transaction {
  font-size: 0.75rem;
}

#cryptocompare {
  display:block;
  padding-top:60px;
  margin: 30px auto;
}

.affiliate-disclosure {
  color: #fff;
  padding-top: 2.5rem;
}

/* Sections */
 .bordertop {
  border-top: 2px solid white;
  padding-bottom: 2rem;
 }

 .borderbottom {
  border-top: 2px solid white;
  margin-top: 4rem;
 }

.feature-title {
  float:right;
  color:yellow;
  margin-top: 10rem;
  margin-right: -12rem;
  text-transform:uppercase;
  font-size: 2rem;
}

#factors {
  float: right;
  margin-left: 40rem;
}

.factors-item {
  color: white;
}

#freedom {
  color: yellow;
}

.feature-title2 {
  float:left;
  color:yellow;
  margin-top: 10rem;
  margin-right: -15rem;
  text-transform:uppercase;
}

.feature-title3 {
  color:yellow;
}

.read-more {
  background: #304ffe;
  padding: 8px 12px;
  font-size: 1.5rem;
  border:none;
  color:white;
  font-weight:300;
  border-radius:5px;
  transition: all 0.25s ease-in-out;
}

.read-more:hover {
background: green;
color:white;
text-decoration:none;
}

.read-more2 {
  background: #304ffe;
  padding: 8px 12px;
  font-size: 1.5rem;
  border:none;
  color:white;
  font-weight:300;
  border-radius:5px;
  transition: all 0.25s ease-in-out;
}

.read-more2:hover {
  background: green;
  color:white;
  text-decoration:none;
  }

  .read-more3 {
    background: #304ffe;
    padding: 8px 12px;
    font-size: 1.5rem;
    border:none;
    color:white;
    font-weight:300;
    border-radius:5px;
    transition: all 0.25s ease-in-out;
  }

  .read-more3:hover {
    background: green;
    color:white;
    text-decoration:none;
  }

.ads {
  height: 90px;
  width: 728px;
  margin:30px auto;
}

.main-btn {
  color:white;
  background:#626890;
  display:inline-block;
  padding:10px 20px;
  border-radius:3px;
  transition: all 0.25s ease-in-out;
  margin-top:50px;
}

.main-btn:hover {
  color:white;
  background:green;
  text-decoration: none;
}

#results a {
  text-decoration:none;
}

#get-eth {
  font-size: 1.3rem;
}


#error {
  font-size:1.4rem;
  color:red;
  display:none;
}

.info-para {
   color:#000;
  line-height:1.5;
}

.info-para-pages {
  color: #000;
 line-height:3;
}

/* Contact */
.policy-container {
  display:flex;
	justify-content: center;
  height:8vh;
}

#footer-2 {
  color: #000;
  padding-bottom: 2rem;
}

.footer-container {
  margin-top: 25rem;
  
} 

/* Date Picker */
.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  margin-left: -8px;
  position: absolute;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  box-sizing: content-box;
  position: absolute;
  border: 8px solid transparent;
  height: 0;
  width: 1px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  content: "";
  z-index: -1;
  border-width: 8px;
  left: -8px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle {
  top: 0;
  margin-top: -8px;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle, .react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  border-top: none;
  border-bottom-color: #f0f0f0;
}

.react-datepicker-popper[data-placement^="bottom"] .react-datepicker__triangle::before {
  top: -1px;
  border-bottom-color: #aeaeae;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  bottom: 0;
  margin-bottom: -8px;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle, .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow, .react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  border-bottom: none;
  border-top-color: #fff;
}

.react-datepicker-popper[data-placement^="top"] .react-datepicker__triangle::before, .react-datepicker__year-read-view--down-arrow::before,
.react-datepicker__month-read-view--down-arrow::before,
.react-datepicker__month-year-read-view--down-arrow::before {
  bottom: -1px;
  border-top-color: #aeaeae;
}

.react-datepicker-wrapper {
  display: inline-block;
}

.react-datepicker {
  font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
  font-size: 0.8rem;
  background-color: #fff;
  color: #000;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  display: inline-block;
  position: relative;
}

.react-datepicker--time-only .react-datepicker__triangle {
  left: 35px;
}

.react-datepicker--time-only .react-datepicker__time-container {
  border-left: 0;
}

.react-datepicker--time-only .react-datepicker__time {
  border-radius: 0.3rem;
}

.react-datepicker--time-only .react-datepicker__time-box {
  border-radius: 0.3rem;
}

.react-datepicker__triangle {
  position: absolute;
  left: 50px;
}

.react-datepicker-popper {
  z-index: 1;
}

.react-datepicker-popper[data-placement^="bottom"] {
  margin-top: 10px;
}

.react-datepicker-popper[data-placement^="top"] {
  margin-bottom: 10px;
}

.react-datepicker-popper[data-placement^="right"] {
  margin-left: 8px;
}

.react-datepicker-popper[data-placement^="right"] .react-datepicker__triangle {
  left: auto;
  right: 42px;
}

.react-datepicker-popper[data-placement^="left"] {
  margin-right: 8px;
}

.react-datepicker-popper[data-placement^="left"] .react-datepicker__triangle {
  left: 42px;
  right: auto;
}

.react-datepicker__header {
  text-align: center;
  background-color: #f0f0f0;
  border-bottom: 1px solid #aeaeae;
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
  padding-top: 8px;
  position: relative;
}

.react-datepicker__header--time {
  padding-bottom: 8px;
  padding-left: 5px;
  padding-right: 5px;
}

.react-datepicker__year-dropdown-container--select,
.react-datepicker__month-dropdown-container--select,
.react-datepicker__month-year-dropdown-container--select,
.react-datepicker__year-dropdown-container--scroll,
.react-datepicker__month-dropdown-container--scroll,
.react-datepicker__month-year-dropdown-container--scroll {
  display: inline-block;
  margin: 0 2px;
}

.react-datepicker__current-month,
.react-datepicker-time__header {
  margin-top: 0;
  color: #000;
  font-weight: bold;
  font-size: 0.944rem;
}

.react-datepicker-time__header {
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;
}

.react-datepicker__navigation {
  background: none;
  line-height: 1.7rem;
  text-align: center;
  cursor: pointer;
  position: absolute;
  top: 10px;
  width: 0;
  padding: 0;
  border: 0.45rem solid transparent;
  z-index: 1;
  height: 10px;
  width: 10px;
  text-indent: -999em;
  overflow: hidden;
}

.react-datepicker__navigation--previous {
  left: 10px;
  border-right-color: #ccc;
}

.react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__navigation--previous--disabled, .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--next {
  right: 10px;
  border-left-color: #ccc;
}

.react-datepicker__navigation--next--with-time:not(.react-datepicker__navigation--next--with-today-button) {
  right: 80px;
}

.react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__navigation--next--disabled, .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__navigation--years {
  position: relative;
  top: 0;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__navigation--years-previous {
  top: 4px;
  border-top-color: #ccc;
}

.react-datepicker__navigation--years-previous:hover {
  border-top-color: #b3b3b3;
}

.react-datepicker__navigation--years-upcoming {
  top: -4px;
  border-bottom-color: #ccc;
}

.react-datepicker__navigation--years-upcoming:hover {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__month-container {
  float: left;
}

.react-datepicker__month {
  margin: 0.4rem;
  text-align: center;
}

.react-datepicker__input-time-container {
  clear: both;
  width: 100%;
  float: left;
  margin: 5px 0 10px 15px;
  text-align: left;
}

.react-datepicker__input-time-container .react-datepicker-time__caption {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container {
  display: inline-block;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input {
  display: inline-block;
  margin-left: 10px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input {
  width: 85px;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-inner-spin-button,
.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__input input[type="time"] {
  -moz-appearance: textfield;
}

.react-datepicker__input-time-container .react-datepicker-time__input-container .react-datepicker-time__delimiter {
  margin-left: 5px;
  display: inline-block;
}

.react-datepicker__time-container {
  float: right;
  border-left: 1px solid #aeaeae;
  width: 70px;
}

.react-datepicker__time-container--with-today-button {
  display: inline;
  border: 1px solid #aeaeae;
  border-radius: 0.3rem;
  position: absolute;
  right: -72px;
  top: 0;
}

.react-datepicker__time-container .react-datepicker__time {
  position: relative;
  background: white;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box {
  width: 70px;
  overflow-x: hidden;
  margin: 0 auto;
  text-align: center;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list {
  list-style: none;
  margin: 0;
  height: calc(195px + (1.7rem / 2));
  overflow-y: scroll;
  padding-right: 0px;
  padding-left: 0px;
  width: 100%;
  box-sizing: content-box;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item {
  height: 30px;
  padding: 5px 10px;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item:hover {
  cursor: pointer;
  background-color: #f0f0f0;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected {
  background-color: #216ba5;
  color: white;
  font-weight: bold;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--selected:hover {
  background-color: #216ba5;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled {
  color: #ccc;
}

.react-datepicker__time-container .react-datepicker__time .react-datepicker__time-box ul.react-datepicker__time-list li.react-datepicker__time-list-item--disabled:hover {
  cursor: default;
  background-color: transparent;
}

.react-datepicker__week-number {
  color: #ccc;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable {
  cursor: pointer;
}

.react-datepicker__week-number.react-datepicker__week-number--clickable:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day-names,
.react-datepicker__week {
  white-space: nowrap;
}

.react-datepicker__day-name,
.react-datepicker__day,
.react-datepicker__time-name {
  color: #000;
  display: inline-block;
  width: 1.7rem;
  line-height: 1.7rem;
  text-align: center;
  margin: 0.166rem;
}

.react-datepicker__day {
  cursor: pointer;
}

.react-datepicker__day:hover {
  border-radius: 0.3rem;
  background-color: #f0f0f0;
}

.react-datepicker__day--today {
  font-weight: bold;
}

.react-datepicker__day--highlighted {
  border-radius: 0.3rem;
  background-color: #3dcc4a;
  color: #fff;
}

.react-datepicker__day--highlighted:hover {
  background-color: #32be3f;
}

.react-datepicker__day--highlighted-custom-1 {
  color: magenta;
}

.react-datepicker__day--highlighted-custom-2 {
  color: green;
}

.react-datepicker__day--selected, .react-datepicker__day--in-selecting-range, .react-datepicker__day--in-range {
  border-radius: 0.3rem;
  background-color: #216ba5;
  color: #fff;
}

.react-datepicker__day--selected:hover, .react-datepicker__day--in-selecting-range:hover, .react-datepicker__day--in-range:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--keyboard-selected {
  border-radius: 0.3rem;
  background-color: #2a87d0;
  color: #fff;
}

.react-datepicker__day--keyboard-selected:hover {
  background-color: #1d5d90;
}

.react-datepicker__day--in-selecting-range:not(.react-datepicker__day--in-range) {
  background-color: rgba(33, 107, 165, 0.5);
}

.react-datepicker__month--selecting-range .react-datepicker__day--in-range:not(.react-datepicker__day--in-selecting-range) {
  background-color: #f0f0f0;
  color: #000;
}

.react-datepicker__day--disabled {
  cursor: default;
  color: #ccc;
}

.react-datepicker__day--disabled:hover {
  background-color: transparent;
}

.react-datepicker__input-container {
  position: relative;
  display: inline-block;
}

.react-datepicker__year-read-view,
.react-datepicker__month-read-view,
.react-datepicker__month-year-read-view {
  border: 1px solid transparent;
  border-radius: 0.3rem;
}

.react-datepicker__year-read-view:hover,
.react-datepicker__month-read-view:hover,
.react-datepicker__month-year-read-view:hover {
  cursor: pointer;
}

.react-datepicker__year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__year-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view:hover .react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-year-read-view:hover .react-datepicker__month-read-view--down-arrow {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-read-view--down-arrow,
.react-datepicker__month-read-view--down-arrow,
.react-datepicker__month-year-read-view--down-arrow {
  border-top-color: #ccc;
  float: right;
  margin-left: 20px;
  top: 8px;
  position: relative;
  border-width: 0.45rem;
}

.react-datepicker__year-dropdown,
.react-datepicker__month-dropdown,
.react-datepicker__month-year-dropdown {
  background-color: #f0f0f0;
  position: absolute;
  width: 50%;
  left: 25%;
  top: 30px;
  z-index: 1;
  text-align: center;
  border-radius: 0.3rem;
  border: 1px solid #aeaeae;
}

.react-datepicker__year-dropdown:hover,
.react-datepicker__month-dropdown:hover,
.react-datepicker__month-year-dropdown:hover {
  cursor: pointer;
}

.react-datepicker__year-dropdown--scrollable,
.react-datepicker__month-dropdown--scrollable,
.react-datepicker__month-year-dropdown--scrollable {
  height: 150px;
  overflow-y: scroll;
}

.react-datepicker__year-option,
.react-datepicker__month-option,
.react-datepicker__month-year-option {
  line-height: 20px;
  width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
}

.react-datepicker__year-option:first-of-type,
.react-datepicker__month-option:first-of-type,
.react-datepicker__month-year-option:first-of-type {
  border-top-left-radius: 0.3rem;
  border-top-right-radius: 0.3rem;
}

.react-datepicker__year-option:last-of-type,
.react-datepicker__month-option:last-of-type,
.react-datepicker__month-year-option:last-of-type {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
  border-bottom-left-radius: 0.3rem;
  border-bottom-right-radius: 0.3rem;
}

.react-datepicker__year-option:hover,
.react-datepicker__month-option:hover,
.react-datepicker__month-year-option:hover {
  background-color: #ccc;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-upcoming,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-upcoming {
  border-bottom-color: #b3b3b3;
}

.react-datepicker__year-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-option:hover .react-datepicker__navigation--years-previous,
.react-datepicker__month-year-option:hover .react-datepicker__navigation--years-previous {
  border-top-color: #b3b3b3;
}

.react-datepicker__year-option--selected,
.react-datepicker__month-option--selected,
.react-datepicker__month-year-option--selected {
  position: absolute;
  left: 15px;
}

.react-datepicker__close-icon {
  background-color: transparent;
  border: 0;
  cursor: pointer;
  outline: 0;
  padding: 0;
  vertical-align: middle;
  position: absolute;
  height: 16px;
  width: 16px;
  top: 25%;
  right: 7px;
}

.react-datepicker__close-icon::after {
  background-color: #216ba5;
  border-radius: 50%;
  bottom: 0;
  box-sizing: border-box;
  color: #fff;
  content: "\00d7";
  cursor: pointer;
  font-size: 12px;
  height: 16px;
  width: 16px;
  line-height: 1;
  margin: -8px auto 0;
  padding: 2px;
  position: absolute;
  right: 0px;
  text-align: center;
}

.react-datepicker__today-button {
  background: #f0f0f0;
  border-top: 1px solid #aeaeae;
  cursor: pointer;
  text-align: center;
  font-weight: bold;
  padding: 5px 0;
  clear: left;
}

.react-datepicker__portal {
  position: fixed;
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.8);
  left: 0;
  top: 0;
  justify-content: center;
  align-items: center;
  display: flex;
  z-index: 2147483647;
}

.react-datepicker__portal .react-datepicker__day-name,
.react-datepicker__portal .react-datepicker__day,
.react-datepicker__portal .react-datepicker__time-name {
  width: 3rem;
  line-height: 3rem;
}

@media (max-width: 400px), (max-height: 550px) {
  .react-datepicker__portal .react-datepicker__day-name,
  .react-datepicker__portal .react-datepicker__day,
  .react-datepicker__portal .react-datepicker__time-name {
    width: 2rem;
    line-height: 2rem;
  }
}

.react-datepicker__portal .react-datepicker__current-month,
.react-datepicker__portal .react-datepicker-time__header {
  font-size: 1.44rem;
}

.react-datepicker__portal .react-datepicker__navigation {
  border: 0.81rem solid transparent;
}

.react-datepicker__portal .react-datepicker__navigation--previous {
  border-right-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--previous:hover {
  border-right-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--previous--disabled, .react-datepicker__portal .react-datepicker__navigation--previous--disabled:hover {
  border-right-color: #e6e6e6;
  cursor: default;
}

.react-datepicker__portal .react-datepicker__navigation--next {
  border-left-color: #ccc;
}

.react-datepicker__portal .react-datepicker__navigation--next:hover {
  border-left-color: #b3b3b3;
}

.react-datepicker__portal .react-datepicker__navigation--next--disabled, .react-datepicker__portal .react-datepicker__navigation--next--disabled:hover {
  border-left-color: #e6e6e6;
  cursor: default;
}

.react-datepicker-wrapper {
  width:100%;
}

.react-datepicker__input-container {
  width:100%;
}

.react-datepicker__input-container input {
  width:100%;
}

/* Tablet */

@media screen and (max-width:1199px) {
  #home .bitcoin-logo {
    width:100%;
    margin-top:100%;
  }

  .description {
    color:white;
    margin-top:40%;
    line-height: 1.5;
    font-size:0.9rem;
  }
}

/* IPad */

@media screen and (max-width:995px) {
  .read-more {
    font-size: 1rem;
  }
}


/* Phones */

@media screen and (max-width:767px) {

.menu {
  text-align:right;

}

.menu label {
  display:block;
  cursor:pointer;
  color:white;
  font-size:1.5rem;
  margin-top:10px;
}

#dd-menu {
  text-align:center;
  width:100%;
  display:none;
  margin: 0 30px 0 0;
}

.menu a {
  display:block;
  margin:0 10px;
  line-height:30px;
  border:1px solid white;
}


#toggle:checked + #dd-menu {
  display:block;
}

  #home .bitcoin-logo {
    width:100%;
    margin-top:10%;
  }

 .description {
  color:white;
  margin-top:25%;
  line-height:1.5;
  font-size:1rem;
 }



}

/* Loader */

/* .a-body {
  margin:0;
  padding:0;
  background: #626890;
  height:100vh; 
}

.loading {
  position:absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  height:40px;
  display:flex;
  align-items:center;
}

.obj {
  width: 6px;
  height: 40px;
  background:white;
  margin:0 3px;
  border-radius: 10px;
  animation: loading 0.8s infinite;
}

.obj:nth-child(2) {
  animation-delay:0.1s;
}
.obj:nth-child(3) {
  animation-delay:0.2s;
}
.obj:nth-child(4) {
  animation-delay:03s;
}
.obj:nth-child(5) {
  animation-delay:0.4s;
}
.obj:nth-child(6) {
  animation-delay:0.5s;
}
.obj:nth-child(7) {
  animation-delay:0.6s;
}
.obj:nth-child(8) {
  animation-delay:0.7s;
}

@keyframes loading {
  0% {
    height:0;
  }
  50% {
    height:40px;
  }
  100% {
    height:0;
  }
} */


  @media screen and (max-width:475px) {

  
  .title {
    font-size: 1.5rem;
    margin-left: 2rem;
    margin-top: 2rem;
  }
  
  .e-container h2 {
    font-size: 1.2rem;
    margin-left: 2rem;
  }
  
  .e-container h3 {
    font-size: 0.80rem;
    margin-left: 1rem;
  }
  
  .e-container {
    width: 20rem;
    display: flex;
    flex-direction: column;
  }
  
  
  input[type="text"] {
    width: 15rem;
    margin-left: 4rem;
  }
  
  label {
    margin-left: 3rem;
  }

  button {
    margin-right: 3rem;
    margin-top: 1rem;
  }

  #results1 h1 {
    margin-left: 2rem;
}

#get-eth {
  margin-left: 1rem;
}
 
  .policy-container {
    font-size: 0.75rem;
  }
  
  #footer-2 {
    font-size: 0.65rem;
    margin-top: 1rem;
    padding-bottom: 4rem;
  }

  
  .react-datepicker__navigation--previous {
    left:1.56rem;
    border-right-color: #ccc;
    border-width: 0.75rem;
    top: -0.94rem;
}

.react-datepicker__navigation--next {
  right: -0.94rem;
  border-left-color: #ccc;
  border-width: 0.75rem;
  top: -0.94rem;
}
     
}
